import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { useIntl, Link } from "gatsby-plugin-intl";

import Button from "../Button";

const Title = styled.h2`
  color: ${props => props.theme.colors.gray};
  text-transform: uppercase;
  letter-spacing: 0.1em;
  position: relative;
`;

const Service = styled.div`
  h3 {
    font-size: 1rem;
    @media (min-width: 768px) {
      font-size: 2rem;
    }
    a {
      color: inherit;
    }
  }
  .button-wrapper {
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    transform: translateX(calc(-100% - 15px));
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    .button-wrapper {
      opacity: 1;
    }
  }
`;

const Services = ({ services }) => {
  const intl = useIntl();

  return (
    <Container className="pt-5 text-center">
      <Title className="text-center mb-4">
        {intl.formatMessage({ id: "services" })}
      </Title>
      <div className="d-inline-block position-relative text-left">
        {services.map(service => (
          <Service key={service.uid} className="position-relative mb-4">
            <h3>
              <Link to={`/servicios/${service.uid}`}>
                {service.data.title.text}
              </Link>
            </h3>

            <div className="button-wrapper position-absolute">
              <Button to={`/servicios/${service.uid}`}>
                {intl.formatMessage({ id: "view_more" })}
              </Button>
            </div>
          </Service>
        ))}
      </div>
    </Container>
  );
};

export default Services;
