import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Parallax } from "react-scroll-parallax";
import { useIntl } from "gatsby-plugin-intl";
import TextLoop from "react-text-loop";

import Button from "../Button";
import Shape from "../../images/shape-1.inline.svg";
import { addGifToContent } from "../../utils";

const Text = styled.div`
  font-size: 1.5rem;
  line-height: 1.2;
  @media (min-width: 768px) {
    font-size: 2.5rem;
    line-height: 2;
  }
`;

const Content = styled.div`
  p {
    margin-bottom: 0.5rem;
  }
`;

const StyledShape = styled(Parallax)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 180px;
  svg {
    width: 100%;
    height: auto;
  }
`;

const ImageWrapper = styled.div`
  @media (min-width: 992px) {
    width: 90%;
  }
`;

const AboutSection = ({ words, copy, content, image }) => {
  const intl = useIntl();
  const img = getImage(image.localFile.childImageSharp);
  const serializer = addGifToContent();

  return (
    <Container className="py-5 position-relative">
      <StyledShape y={[0, 150]}>
        <Shape />
      </StyledShape>
      <Row className="pt-5 mt-5 align-items-center">
        <Col lg={7}>
          <Parallax y={[-20, 20]}>
            <Text>
              <div className="mb-5">
                {intl.formatMessage({ id: "we_create" })}{" "}
                <TextLoop
                  springConfig={{ stiffness: 300, damping: 40 }}
                  interval={1500}
                >
                  {words.split(",").map(word => {
                    const trimmedWord = word.trim();
                    return <span key={trimmedWord}>{trimmedWord}</span>;
                  })}
                </TextLoop>
              </div>
              {copy}
            </Text>
            <Button className="mt-5" to="/proyectos">
              {intl.formatMessage({ id: "view_projects" })}
            </Button>
          </Parallax>
        </Col>
        <Col lg={5} className="text-right">
          <ImageWrapper className="ml-auto mt-4">
            <GatsbyImage image={img} alt={image.alt || ""} />
          </ImageWrapper>
        </Col>
      </Row>
      <Row>
        <Col lg={6} className="ml-auto pb-5 mt-5">
          <Content className="pb-5">
            <RichText render={content} htmlSerializer={serializer} />
          </Content>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutSection;
