import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-intl";

import { StyledButton } from "../Button";
import { FormModalContext } from "../../providers/FormModalProvider";

const Title = styled.h2`
  color: ${props => props.theme.colors.gray};
  text-transform: uppercase;
  letter-spacing: 0.1em;
`;

const ContactSection = ({ images }) => {
  const intl = useIntl();
  const { setModalState } = useContext(FormModalContext);
  const leftImg = getImage(images.left.localFile.childImageSharp);
  const rightImg = getImage(images.right.localFile.childImageSharp);

  return (
    <Container fluid className="py-5 my-5">
      <Title className="text-center mb-5 pb-5">
        {intl.formatMessage({ id: "take_action" })}
      </Title>
      <Row>
        <Col md={6} className="mb-5 mb-md-0">
          <Row className="align-items-center">
            <Col md={7}>
              <GatsbyImage image={leftImg} alt={images.left.alt || ""} />
            </Col>
            <Col>
              <p className="text-uppercase mb-4">
                {intl
                  .formatMessage({ id: "invest_with_us" })
                  .split(" ")
                  .map(word => (
                    <span key={word} className="d-md-block mr-2 mr-md-0">
                      {word}
                    </span>
                  ))}
              </p>
              <StyledButton
                onClick={e => {
                  e.preventDefault();
                  setModalState({
                    isOpen: true,
                    type: "investor",
                  });
                }}
              >
                {intl.formatMessage({ id: "view_more" })}
              </StyledButton>
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <Row className="align-items-center">
            <Col className="text-right order-1 order-md-0">
              <p className="d-md-inline-block text-left mb-4 mb-md-0">
                {intl
                  .formatMessage({ id: "start_project" })
                  .split(" ")
                  .map(word => (
                    <span
                      key={word}
                      className="text-uppercase d-md-block mr-2 mr-md-0"
                    >
                      {word}
                    </span>
                  ))}
                <StyledButton
                  onClick={e => {
                    e.preventDefault();
                    setModalState({
                      isOpen: true,
                      type: "project",
                    });
                  }}
                  className="mt-4 d-block"
                >
                  {intl.formatMessage({ id: "view_more" })}
                </StyledButton>
              </p>
            </Col>
            <Col md={7} className="order-0 order-md-1">
              <GatsbyImage image={rightImg} alt={images.right.alt || ""} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactSection;
